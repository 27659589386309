export function Page404() {
  return (
    <>
      <div className="main">
        <div className="container-home">
          <h1>Page introuvable</h1>
        </div>
      </div>
    </>
  );
}
